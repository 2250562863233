import Form from '@/components/molecules/Form'

import {FormType} from '@/components/types/auth'

import useCsrfToken from '@/functions/wordpress/auth/csrfToken'

import {useContext, useState} from 'react'

import LoginForm from './LoginForm'

import RegistrationForm from './RegistrationForm'

import ResetPasswordForm from './ResetPasswordForm'

import SetNewPasswordForm from './SetNewPasswordForm'

import {AppContext} from '@/components/common/AppContext'
import {useOverlayContext} from '@/components/common/ProfileOverlayContext'
import {useRouter} from 'next/router'
import submitForm, {
  sendPasswordResetEmail,
  submitNewPassword,
  submitRegistrationForm
} from './submitForms'

const FormWrapper = ({
  formType,
  setFormType,
  keyFromUrl,
  userLoginFromUrl,
  isPage,
  isCheckout,
  isKonto
}) => {
  const [isEmailSuccess, setIsEmailSuccess] = useState(false)

  const {isFormOpen, setIsFormOpen, isMoodleActivated} = useOverlayContext()

  const [isRegistrierenSuccess, setIsRegistrierenSuccess] = useState(false)

  const [isNewPasswordSuccess, setIsNewPasswordSuccess] = useState(false)

  const {isLoading, setIsLoading, setErrorMessage, setSuccessMessage} =
    useContext(AppContext)

  const csrfToken = useCsrfToken()

  const router = useRouter()

  const handleSwitchFormType = (formType) => {
    setFormType(formType)
  }

  const handleCloseForm = () => {
    if (setIsFormOpen) setIsFormOpen(false)
    setTimeout(() => {
      setFormType(FormType.Login)
    }, 800)
  }

  const formProps = isPage
    ? {}
    : {
        className: isFormOpen ? 'is--active' : '',
        onClose: handleCloseForm
      }
  switch (formType) {
    case FormType.Login:
      return (
        <Form
          {...formProps}
          id="login-form"
          title="Anmelden"
          page={isPage}
          formDefaults={{
            username: '',
            password: '',
            emailOnly: true
          }}
          onSubmit={async (values) => {
            if (values.emailOnly === true) {
              try {
                setIsLoading(true)
                values.username = values.username?.toLowerCase()
                await submitForm(
                  values,
                  setErrorMessage,
                  setSuccessMessage,
                  handleCloseForm,
                  csrfToken,
                  isFormOpen,
                  isMoodleActivated,
                  router
                )
              } catch (error) {
                setErrorMessage(error)
              } finally {
                setIsLoading(false)
              }
            }
          }}
        >
          {(formikProps) => (
            <LoginForm
              formikProps={formikProps}
              handleSwitchFormType={handleSwitchFormType}
              csrfToken={csrfToken}
              isLoading={isLoading}
              isCheckout={isCheckout}
              isKonto={isKonto}
            />
          )}
        </Form>
      )
    case FormType.Register:
      return (
        <Form
          {...formProps}
          id="register-form"
          title="Registrieren"
          registrieren={!isPage}
          page={isPage}
          formDefaults={{
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            password: '',
            passwordConfirm: '',
            emailOnly: true,
            termsCheckbox: false
          }}
          onSubmit={async (values) => {
            if (values.emailOnly === true) {
              try {
                setIsLoading(true)
                values.email = values.email?.toLowerCase()
                values.emailConfirm = values.emailConfirm?.toLowerCase()

                await submitRegistrationForm(
                  values,
                  setIsRegistrierenSuccess,
                  setSuccessMessage,
                  setErrorMessage,
                  csrfToken
                )
              } catch (error) {
                setErrorMessage(error)
              } finally {
                setIsLoading(false)
              }
            }
          }}
        >
          {(formikProps) => (
            <RegistrationForm
              formikProps={formikProps}
              handleSwitchFormType={handleSwitchFormType}
              isRegistrierenSuccess={isRegistrierenSuccess}
              handleCloseForm={handleCloseForm}
              csrfToken={csrfToken}
              isLoading={isLoading}
              isCheckout={isCheckout}
              isKonto={isKonto}
            />
          )}
        </Form>
      )
    case FormType.ResetPassword:
      return (
        <Form
          {...formProps}
          id="reset-password-form"
          title="Passwort zurücksetzen"
          page={isPage}
          formDefaults={{
            username: '',
            emailOnly: true
          }}
          onSubmit={async (values) => {
            if (values.emailOnly === true) {
              try {
                setIsLoading(true)
                values.username = values.username?.toLowerCase()

                await sendPasswordResetEmail(
                  values,
                  setIsEmailSuccess,
                  setSuccessMessage,
                  setErrorMessage
                )
              } catch (error) {
                setErrorMessage(error)
              } finally {
                setIsLoading(false)
              }
            }
          }}
        >
          {(formikProps) => (
            <ResetPasswordForm
              formikProps={formikProps}
              handleSwitchFormType={handleSwitchFormType}
              isEmailSuccess={isEmailSuccess}
              csrfToken={csrfToken}
              isLoading={isLoading}
              isCheckout={isCheckout}
              handleCloseForm={handleCloseForm}
            />
          )}
        </Form>
      )
    case FormType.SetNewPassword:
      return (
        <Form
          {...formProps}
          id="set-new-password-form"
          title="Neues Passwort festlegen"
          formDefaults={{
            password: '',
            confirmPassword: ''
          }}
          onSubmit={async (values) => {
            try {
              setIsLoading(true)

              await submitNewPassword(
                values,
                userLoginFromUrl,
                keyFromUrl,
                setIsNewPasswordSuccess,
                setSuccessMessage,
                setErrorMessage
              )
            } catch (error) {
              setErrorMessage(error)
            } finally {
              setIsLoading(false)
            }
          }}
        >
          {(formikProps) => (
            <SetNewPasswordForm
              formikProps={formikProps}
              isNewPasswordSuccess={isNewPasswordSuccess}
              isLoading={isLoading}
              handleSwitchFormType={handleSwitchFormType}
              handleCloseForm={handleCloseForm}
              isCheckout={isCheckout}
            />
          )}
        </Form>
      )
    default:
      return null
  }
}

export default FormWrapper
