import Button from '@/components/atoms/Button'

import {useWordPressContext} from '@/components/common/WordPressProvider'

import cn from 'classnames'

import {useContext, useEffect, useRef, useState} from 'react'

import CookiesObject from 'universal-cookie'

import {useTranslation} from 'next-i18next'

import {cookieSettings} from '../../../../config'

import Icon from '../../../atoms/Icon/Icon'

import CookieHelper from './CookieHelper'

import useOutsideAlerter from '@/components/atoms/OutsideAlerter/OutsideAlerter'

import styles from './Cookies.module.scss'

import {CookiesFormContext} from './CookiesFormContext'

const Cookies = () => {
  const {themeOptions} = useWordPressContext()

  const {t} = useTranslation('global')

  const ref = useRef(null)

  const cookies = themeOptions?.cookies || []

  const {isCookiesFormOpen, setIsCookiesFormOpen} =
    useContext(CookiesFormContext)

  useOutsideAlerter({
    ref,
    isOpen: isCookiesFormOpen,
    setIsOpen: setIsCookiesFormOpen,
    isOverlay: true
  })

  const cookiesObj = new CookiesObject()

  const [cookiesNameArray, setCookiesNameArray] = useState({})

  useEffect(() => {
    cookies.forEach((cookieElment) => {
      const obj = {}
      obj[cookieElment.cookieName] =
        cookiesObj.get(cookieElment.cookieName) == 'true' ||
        cookieElment.cookieName == 'allowFunctional'
          ? true
          : false
      setCookiesNameArray((prevObject) => ({...prevObject, ...obj}))
    })
  }, [cookies])

  const handleSubmit = () => {
    for (const property in cookiesNameArray) {
      cookiesObj.set(
        property,
        cookiesNameArray[property] == true ? 'true' : 'false',
        cookieSettings
      )
      if (property === 'allowStatistics' && cookiesNameArray[property] == true)
        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted'
        })
      else
        window.gtag('consent', 'update', {
          ad_storage: 'denied',
          analytics_storage: 'denied'
        })
    }
    handleCloseForm()
  }

  const handleCloseForm = () => {
    setIsCookiesFormOpen(false)
  }

  return (
    <div
      className={cn(styles.cookies, isCookiesFormOpen ? styles.isActive : '')}
    >
      <div ref={ref} className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <Icon
            size={40}
            sizeViewbox={40}
            icon="closeForm"
            className={styles.closeForm}
            onClick={() => handleCloseForm()}
          />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.title}>{t('cookiesSettings')}</div>
          {cookies &&
            cookies.map((cookie, i) => (
              <CookieHelper
                key={i}
                cookie={cookie}
                object={cookiesNameArray}
                setObject={setCookiesNameArray}
              />
            ))}
          <div className={styles.buttonWrapper}>
            <Button
              text={t('saveAndCloseButton')}
              type="filled"
              icon={null}
              onClick={() => handleSubmit()}
              className={styles.button}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cookies
